<script lang="ts" setup>
	useHead({
		title: "LeadX",
		meta: [{ name: "description", content: "" }],
	});
</script>

<template>
	<slot />
</template>

<style scoped lang="scss">
	html {
	}
</style>
